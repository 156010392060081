import { anonymousTemplate, authenticatedTemplate } from './login.template';
import { AuthSessionProps, LoginQueryParam } from './types';
import levity from './levity';
import stringReplacer from '../../../../core/src/ts/stringReplacer';
class Login extends HTMLElement {
    static authSession: AuthSessionProps = null;
    static iambus = null;
    destinationUrl: string | null = '';
    idp: string | null = null;
    appId: string | null = null;
    constructor() {
        super();
    }

    connectedCallback() {
        const eventBus = levity.getEventBus();

        const sendLoginEvent = () => {
            const loginEvent = new CustomEvent('loginEvent', {
                detail: {
                    role: levity.getAuthSession().basicProfile.isStudent ? 'student' : 'professional',
                    firstName: levity.getAuthSession().basicProfile.firstName,
                },
                bubbles: true,
                cancelable: false,
                composed: true,
            });
            this.dispatchEvent(loginEvent);
        };

        const attachEvents = () => {
            //Event listener for Sign In button - Anchor
            const signInBtn = this.querySelector('.cbw-header-login');
            signInBtn?.addEventListener('click', this.signInButtonHandler);

            //Event listener for Sign In button - Spans
            const signInBtnSpans = this.querySelectorAll('.cbw-header-login span');
            signInBtnSpans.forEach(span => {
                span?.addEventListener('click', this.signInButtonHandler);
            });
        };

        // Show content for logged in users.
        eventBus.on(levity.events.Login, () => {
            Login.updateSession();
            // Update component markup.
            const replacements: { [key: string]: string } = {
                firstName: levity.getAuthSession().basicProfile.firstName,
            };
            this.innerHTML = stringReplacer(authenticatedTemplate, replacements);
            //trigger login event for slide down component
            sendLoginEvent();
            attachEvents();
        });

        // Show content for anonymous users.
        eventBus.on(levity.events.Logout, () => {
            Login.updateSession();
            this.innerHTML = anonymousTemplate;
            const logoutEvent = new CustomEvent('logoutEvent', {
                bubbles: true,
                cancelable: false,
                composed: true,
            });
            this.dispatchEvent(logoutEvent);
            attachEvents();
        });

        if (levity.getAuthSession().isLoggedIn) {
            // Update component markup.
            const replacements: { [key: string]: string } = {
                firstName: levity.getAuthSession().basicProfile.firstName,
            };
            this.innerHTML = stringReplacer(authenticatedTemplate, replacements);
            sendLoginEvent();
        } else {
            this.innerHTML = anonymousTemplate;
        }

        attachEvents();
    }
    static updateSession = () => {
        Login.authSession = levity.getAuthSession();
    };

    signInButtonHandler = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
        event.preventDefault();
        event.stopPropagation();

        if (levity.getAuthSession().isLoggedIn) {
            const loginButtonPressEvent = new CustomEvent('loginButtonPress', {
                bubbles: true,
                cancelable: false,
                composed: true,
            });
            this.dispatchEvent(loginButtonPressEvent);
        } else {
            window && window.top && (window.top.location.href = this.prepareLoginURL());
        }
    };

    prepareLoginURL = () => {
        const baseLoginURL = 'https://account.collegeboard.org/login/login';
        const defaultUrl = /iframe/.test(window.location.href) ? window.parent.location.href : window.location.href;
        this.destinationUrl = defaultUrl;
        this.idp = this.getAttribute('idp');
        this.appId = this.getAttribute('app-id');
        this.destinationUrl = this.getAttribute('destination-url');
        const queryParams: LoginQueryParam = {
            appId: this.appId || '',
            idp: this.idp || '',
            DURL: this.destinationUrl === undefined ? defaultUrl : this.destinationUrl,
        };
        const loginURL = new URL(baseLoginURL);

        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                loginURL.searchParams.set(key, (queryParams as any)[key]);
            }
        }

        return loginURL.toString();
    };
}

export default Login;
