const setFocus = (topTargetId?: string): void => {
    if (topTargetId === 'cbw-header') {
        const shadowRoot = document.querySelector('cbw-header')?.shadowRoot;
        // Select the first anchor element inside the shadow root
        const firstAnchor = shadowRoot?.querySelector('a');
        // if anchor element was found, focus
        firstAnchor && firstAnchor.focus();
    } else {
        const header: HTMLElement | null = document.querySelector(`#${topTargetId}`);
        if (header) {
            // make sure element is focusable
            if (!header.getAttribute('tabindex')) {
                header.setAttribute('tabindex', '-1');
            }
            header.focus();
        }
    }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const pageScrollAnimate = (destination: number | HTMLElement, topTargetId?: string): void => {
    const documentHeight: number = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset,
    );

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        setFocus(topTargetId);
        return;
    }

    const scroll = () => {
        const fixedOffset = destinationOffsetToScroll.toFixed();
        const onScroll = () => {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                setFocus(topTargetId);
            }
        };

        window.addEventListener('scroll', onScroll);

        onScroll();

        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    };
    scroll();
};

export const scrollToGlobalHeader = (event: MouseEvent, topTargetId?: string): void => {
    event.preventDefault();

    pageScrollAnimate(0, topTargetId);
};
