import { HeaderLink } from './types';

export const defaultHeaderLinks: HeaderLink[] = [
    {
        name: 'SAT',
        url: 'https://collegereadiness.collegeboard.org/sat',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'sat',
        description: 'K-12 Resources',
    },
    {
        name: 'SAT Subject Tests',
        url: 'https://collegereadiness.collegeboard.org/sat-subject-tests',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        group: 'SAT',
        linkCode: 'satsubj',
        description: 'SAT Subject Tests',
    },
    {
        name: 'PSAT/NMSQT',
        url: 'https://collegereadiness.collegeboard.org/psat-nmsqt-psat-10',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'pn',
    },
    {
        name: 'PSAT 10',
        url: 'https://collegereadiness.collegeboard.org/psat-nmsqt-psat-10',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'pn10',
    },
    {
        name: 'PSAT 8/9',
        url: 'https://collegereadiness.collegeboard.org/psat-8-9',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'p89',
    },
    {
        name: 'SpringBoard',
        url: 'https://springboard.collegeboard.org/',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'sb',
    },
    {
        name: 'AP',
        url: 'https://ap.collegeboard.org/',
        type: 'default',
        category: 'K-12',
        property: 'programs',
        logo: true,
        linkCode: 'ap',
    },
    {
        name: 'AP Students',
        url: 'https://apstudent.collegeboard.org/',
        type: 'program',
        category: 'K-12',
        group: 'AP',
        linkCode: 'aps',
    },
    {
        name: 'AP Central',
        url: 'https://apcentral.collegeboard.org/',
        type: 'program',
        category: 'K-12',
        group: 'AP',
        linkCode: 'apc',
    },
    {
        name: 'Pre-AP',
        url: 'https://pre-ap.collegeboard.org',
        type: 'program',
        category: 'K-12',
        group: 'AP',
        linkCode: 'preap',
    },
    {
        name: 'Access',
        url: 'https://bigfuture.collegeboard.org/',
        type: 'default',
        category: 'Access',
        property: 'programs',
        logo: true,
        linkCode: 'accs',
        description: 'Access Resources',
    },
    {
        name: 'College Planning',
        url: 'https://bigfuture.collegeboard.org/',
        type: 'default',
        category: 'Access',
        group: 'Access',
        linkCode: 'cp',
    },
    {
        name: 'College Search',
        url: 'https://bigfuture.collegeboard.org/college-search',
        type: 'default',
        category: 'Access',
        group: 'Access',
        linkCode: 'cs',
    },
    {
        name: 'ACCUPLACER',
        url: 'https://accuplacer.collegeboard.org/',
        type: 'default',
        category: 'Higher Ed',
        property: 'programs',
        logo: true,
        linkCode: 'accu',
        description: 'Resources for Higher Education',
    },
    {
        name: 'CLEP',
        url: 'https://clep.collegeboard.org/',
        type: 'default',
        category: 'Higher Ed',
        property: 'programs',
        logo: true,
        linkCode: 'clep',
    },
    {
        name: 'CSS Profile',
        url: 'https://cssprofile.collegeboard.org/',
        type: 'default',
        category: 'Higher Ed',
        property: 'programs',
        logo: true,
        linkCode: 'css',
    },
    {
        name: 'Search',
        url: 'https://cbsearch.collegeboard.org/',
        type: 'default',
        property: 'programs',
        category: 'Higher Ed',
        logo: true,
        linkCode: 'cbsch',
    },
    {
        name: 'PowerFAIDS',
        url: 'https://powerfaids.collegeboard.org/',
        type: 'default',
        category: 'Higher Ed',
        property: 'programs',
        logo: true,
        linkCode: 'pfaid',
    },
    {
        name: 'College Board Homepage',
        url: 'https://www.collegeboard.org/',
        type: 'mobile',
        category: 'Organization',
        linkCode: 'cb',
        description: 'College Board Organizational Resources',
    },
    {
        name: 'Professionals',
        url: 'https://professionals.collegeboard.org/',
        type: 'default',
        category: 'Organization',
        linkCode: 'prof',
    },
    {
        name: 'Parents',
        url: 'https://parents.collegeboard.org/',
        type: 'default',
        category: 'Organization',
        linkCode: 'parents',
    },
    {
        name: 'Help',
        url: 'https://pages.collegeboard.org/help',
        type: 'default',
        category: 'Organization',
        linkCode: 'help',
    },
    {
        name: 'More',
        url: 'https://www.collegeboard.org/sitemap',
        type: 'more',
        category: 'Organization',
        linkCode: 'morecb',
    },
    {
        name: 'Facebook',
        url: 'https://www.facebook.com/thecollegeboard',
        type: 'social',
        category: 'Organization',
        group: 'More',
        glyph: 'facebook',
        linkCode: 'sm-fb',
        target: '_blank',
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/collegeboard',
        type: 'social',
        category: 'Organization',
        group: 'More',
        glyph: 'twitter',
        linkCode: 'sm-tw',
        target: '_blank',
    },
    {
        name: 'YouTube',
        url: 'https://www.youtube.com/user/collegeboard',
        type: 'social',
        category: 'Organization',
        group: 'More',
        glyph: 'youtube',
        linkCode: 'sm-yt',
        target: '_blank',
    },
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/the-college-board',
        type: 'social',
        category: 'Organization',
        group: 'More',
        glyph: 'linkedin',
        linkCode: 'sm-li',
        target: '_blank',
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/collegeboard/',
        type: 'social',
        category: 'Organization',
        group: 'More',
        glyph: 'instagram',
        linkCode: 'sm-in',
        target: '_blank',
    },
];
