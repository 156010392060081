import { FooterLink } from './types';

export const defaultFooterLinks: FooterLink[] = [
    {
        name: 'programs',
        url: 'https://www.collegeboard.org',
        label: 'Programs',
        linkCode: '',
        category: '',
        column: '1',
    },
    {
        name: 'SAT',
        label: 'SAT<sup>&reg;</sup>',
        url: 'https://satsuite.collegeboard.org/sat',
        category: 'programs',
        linkCode: 'sat',
    },
    {
        name: 'PSAT/NMSQT',
        label: 'PSAT/NMSQT<sup>&reg;</sup>',
        url: 'https://satsuite.collegeboard.org/psat-nmsqt',
        category: 'programs',
        linkCode: 'pn',
    },
    {
        name: 'PSAT 10',
        label: 'PSAT<sup>&trade;</sup> 10',
        url: 'https://satsuite.collegeboard.org/psat-10',
        category: 'programs',
        linkCode: 'p10',
    },
    {
        name: 'PSAT 8/9',
        label: 'PSAT<sup>&trade;</sup> 8/9',
        url: 'https://satsuite.collegeboard.org/psat-8-9',
        category: 'programs',
        linkCode: 'p89',
    },
    {
        name: 'SpringBoard',
        label: 'SpringBoard<sup>&reg;</sup>',
        url: 'https://springboard.collegeboard.org/',
        category: 'programs',
        linkCode: 'sb',
    },
    {
        name: 'Pre-AP',
        label: 'Pre-AP<sup>&reg;</sup>',
        url: 'https://pre-ap.collegeboard.org',
        category: 'programs',
        linkCode: 'preap',
    },
    {
        name: 'AP',
        label: 'AP<sup>&reg;</sup>',
        url: 'https://ap.collegeboard.org',
        category: 'programs',
        linkCode: 'ap',
    },
    {
        name: 'BigFuture',
        label: 'BigFuture<sup>&reg;</sup>',
        url: 'https://bigfuture.collegeboard.org/',
        category: 'programs',
        linkCode: 'cp',
    },
    {
        name: 'ACCUPLACER',
        label: 'ACCUPLACER<sup>&reg;</sup>',
        url: 'https://accuplacer.collegeboard.org/',
        category: 'programs',
        linkCode: 'accu',
    },
    {
        name: 'CLEP',
        label: 'CLEP<sup>&reg;</sup>',
        url: 'https://clep.collegeboard.org/',
        category: 'programs',
        linkCode: 'clep',
    },
    {
        name: 'CSS Profile',
        label: 'CSS Profile<sup>&reg;</sup>',
        url: 'https://cssprofile.collegeboard.org/',
        category: 'programs',
        linkCode: 'css',
    },
    {
        name: 'Search',
        url: 'https://cbsearch.collegeboard.org/',
        category: 'programs',
        linkCode: 'cbsch',
    },
    {
        name: 'PowerFAIDS',
        label: 'PowerFAIDS<sup>&trade;</sup>',
        url: 'https://powerfaids.collegeboard.org/',
        category: 'programs',
        linkCode: 'pfaid',
    },
    {
        name: 'organization',
        url: 'https://www.collegeboard.org',
        label: 'College Board',
        linkCode: '',
        category: '',
        column: '2',
    },
    {
        name: 'About Us',
        url: 'https://about.collegeboard.org/',
        category: 'organization',
        linkCode: 'abt',
    },
    {
        name: 'Careers',
        url: 'https://careers.collegeboard.org/ ',
        category: 'organization',
        linkCode: 'car',
    },
    {
        name: 'Membership',
        url: 'https://membership.collegeboard.org',
        category: 'organization',
        linkCode: 'mem',
    },
    {
        name: 'Newsroom',
        url: 'https://newsroom.collegeboard.org',
        category: 'organization',
        linkCode: 'news',
    },
    {
        name: 'Research',
        url: 'https://research.collegeboard.org/',
        category: 'organization',
        linkCode: 'rsch',
    },
    {
        name: 'Services for Students with Disabilities',
        url: 'https://accommodations.collegeboard.org/',
        category: 'organization',
        linkCode: 'ssd',
    },
    {
        name: 'Educational Opportunity Center',
        url: 'https://information.collegeboard.org/educational-opportunity-center/',
        category: 'organization',
        linkCode: 'eoc',
    },
    {
        name: 'College Board Blog',
        url: 'https://blog.collegeboard.org/',
        category: 'organization',
        linkCode: 'blog',
    },
    {
        name: 'The Elective',
        url: 'https://elective.collegeboard.org/',
        category: 'organization',
        linkCode: 'elective',
    },
    {
        name: 'College Board Foundation ',
        url: 'https://foundation.collegeboard.org/',
        category: 'organization',
        linkCode: 'foundation',
    },
    {
        name: 'extra',
        url: 'https://www.collegeboard.org',
        label: '',
        linkCode: '',
        category: '',
        column: '3',
    },
    {
        name: 'Help',
        url: 'https://support.collegeboard.org',
        category: 'extra',
        linkCode: 'help',
    },
    {
        name: 'Contact Us',
        url: 'https://about.collegeboard.org/contact-us',
        category: 'extra',
        linkCode: 'contact',
    },
    {
        name: 'More',
        url: 'https://www.collegeboard.org/sitemap',
        category: 'extra',
        linkCode: 'morecb',
    },
    {
        name: 'Doing Business',
        url: 'https://about.collegeboard.org/doing-business',
        category: 'extra',
        linkCode: 'bus',
    },
    {
        name: 'Compliance',
        url: 'https://secure.ethicspoint.com/domain/en/report_custom.asp?clientid=18136',
        category: 'extra',
        linkCode: 'comp',
    },
    {
        name: 'Legal Terms for Students',
        url: 'https://privacy.collegeboard.org/student-legal-terms',
        category: 'extra',
        linkCode: 'stterms',
    },
    {
        name: 'Legal Terms for Educators and Institutions',
        url: 'https://privacy.collegeboard.org/educator-legal-terms',
        category: 'extra',
        linkCode: 'edterms',
    },
    {
        name: 'Privacy Center',
        url: 'https://privacy.collegeboard.org',
        category: 'extra',
        linkCode: 'pp',
    },
    {
        name: 'Privacy Settings',
        url: 'https://my.collegeboard.org/profile/privacy',
        category: 'extra',
        linkCode: 'ps',
    },
    {
        name: 'Copyright and Trademark Permissions',
        url: 'https://privacy.collegeboard.org/copyright-trademark',
        category: 'extra',
        linkCode: 'permissions',
    },
    {
        name: 'Report Cheating',
        url: 'https://forms.collegeboard.org/reportcheating/s/',
        category: 'extra',
        linkCode: 'rc',
    },
];
