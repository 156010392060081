import { LoginLink } from '../Login/types';

export const defaultLoginLinks: LoginLink[] = [
    { name: 'My SAT', url: 'https://mysat.collegeboard.org', type: 'student', group: 'links', linkCode: 'st-mysat' },
    {
        name: 'PSAT/NMSQT, PSAT 10, and PSAT 8/9 Scores',
        url: 'https://studentscores.collegeboard.org/home',
        type: 'student',
        group: 'links',
        linkCode: 'st-psatscrs',
    },
    {
        name: 'My AP',
        url: 'https://apstudents.collegeboard.org/',
        type: 'student',
        group: 'links',
        linkCode: 'st-apscrs',
    },
    {
        name: 'My Colleges',
        url: 'https://collegesearch.collegeboard.org/college-list',
        type: 'student',
        group: 'links',
        linkCode: 'st-mycolls',
    },
    {
        name: 'Account Settings',
        url: 'https://my.collegeboard.org/profile/information',
        type: 'student',
        group: 'controls',
        icon: 'cb-icon-icn_settings',
        linkCode: 'st-acct',
    },
    {
        name: 'My Dashboard',
        url: 'https://cbaccount.collegeboard.org/professional/dashboard',
        type: 'professional',
        group: 'links',
        linkCode: 'pr-dash',
    },
    {
        name: 'Account Settings',
        url: 'https://cbaccount.collegeboard.org/professional/viewProfile',
        type: 'professional',
        group: 'controls',
        icon: 'cb-icon-icn_settings',
        linkCode: 'pr-acct',
    },
];
